import React from "react";
import Styles from "./RarityRow.module.scss";

const RarityRow = (
	{
		rarity,
		value,
	}: {
		rarity: string,
		value: number
	}) => {

	return (
		<div className={Styles.rarityRow}>
			<p>{rarity}</p>
			<p>{value}%</p>
		</div>
	);
};

export default RarityRow;

import React from "react";
import { PagePath } from "../../helpers/PageHelper";
import { L } from "../../l10n/L10n";
import { HeaderScrollBtn } from "../HeaderScrollBtn";
import LoginContainerView, { LoginContainerStyle } from "../LoginContainerView";
import HeaderContainer from "../shared/HeaderContainer";
import HeaderDecor from "../shared/HeaderDecor";
import HeaderInternalLink from "../shared/HeaderInternalLink";
import HeaderPanel from "../shared/HeaderPanel";

const ManaEnchantingHeader = () => (
	<HeaderContainer>
		<HeaderPanel>
			<HeaderInternalLink href={PagePath.MAIN}>{L.page.legend}</HeaderInternalLink>
			<HeaderDecor/>
			<HeaderScrollBtn href="#mana">Mana</HeaderScrollBtn>
			<HeaderDecor/>
			<HeaderScrollBtn href="#pack">Pack</HeaderScrollBtn>
			<HeaderDecor/>
			<HeaderScrollBtn href="#heroes">Heroes</HeaderScrollBtn>
		</HeaderPanel>
		<HeaderPanel>
			<LoginContainerView styleType={LoginContainerStyle.HEADER}/>
		</HeaderPanel>
	</HeaderContainer>
);

export default ManaEnchantingHeader;
import { IPackJson } from "@pro/common/conf";
import { observer } from "mobx-react";
import React from "react";
import { app } from "../App";
import Button, { ButtonStyle } from "../components/Button";
import Cards from "../components/Cards";
import DecoratedTitleView from "../components/DecoratedTitleView";
import Footer from "../components/footer";
import GameLink from "../components/shared/GameLink";
import ManaEnchantingHeader from "../components/mana-enchanting/ManaEnchantingHeader";
import ManaEnchantingMenu from "../components/mana-enchanting/ManaEnchantingMenu";
import RarityRow from "../components/mana-enchanting/RarityRow";
import metaFrame from "../components/mFrame.module.scss";
import SEO from "../components/seo";
import LayoutBase from "../components/shared/LayoutBase";
import social from "../components/social.module.scss";
import VideoFrame from "../components/VideoFrame";
import { PagePath } from "../helpers/PageHelper";
import background_img from "../images/background_img.jpg";
import heroPackImg from "../images/mana_enchanting/hero_pack_img.png";
import logoImg from "../images/mana_enchanting/logo.png";
import manaImg from "../images/mana_enchanting/mana_img.png";
import poolImg from "../images/mana_enchanting/pool_img.png";
import middleFacebook from "../images/social/middle_facebook.png";
import middleMedium from "../images/social/middle_medium.png";
import middleTelegram from "../images/social/middle_telegram.png";
import middleTwitter from "../images/social/middle_twitter.png";
import middleYoutube from "../images/social/middle_youtube.png";
import BackgroundVideo from "../videos/manaEnchanting.mp4";
import Styles from "./manaEnchanting.module.scss";
import { L } from "../l10n/L10n";

const manaEnchantingPage = observer(() => {
	const rarityPercents = app.assetConf.findByAttribId<IPackJson>("p5")?.rarity_percent ?? [52, 24, 11, 8, 4, 1];
	let booksRarity = [
		{rarity: L.rarity.common, value: rarityPercents[0]},
		{rarity: L.rarity.rare, value: rarityPercents[1]},
		{rarity: L.rarity.unique, value: rarityPercents[2]},
		{rarity: L.rarity.epic, value: rarityPercents[3]},
		{rarity: L.rarity.legendary, value: rarityPercents[4]},
		{rarity: L.rarity.mythic, value: rarityPercents[5]},
	];

	return (
		<LayoutBase
			header={<ManaEnchantingHeader/>}
			menu={<ManaEnchantingMenu/>}
		>
			<SEO title="Tribal Books"
			     description="Tribal Books is a free-to-play online digital collectible nft-card game"/>
			<div className={Styles.container}>
				<div className={Styles.banner}>
					{/*<VideoFrame video={BackgroundVideo}>*/}
					<div className={Styles.textImg}>
						<img src={logoImg} alt={"logo img"}/>
					</div>
					<div className={Styles.text}>
						<p>{L.manaEnchanting.the_next_phase}</p>
						<h1>
							{L.manaEnchanting.the_mana_enchanting}
						</h1>
					</div>
					<GameLink href={PagePath.GAME_HOME}>{L.manaEnchanting.play}</GameLink>
					{/*</VideoFrame>*/}
				</div>
				<div className={Styles.content}>
					<div className={Styles.mana} id="mana">
						<DecoratedTitleView text={L.manaEnchanting.title.mana}/>
						<div className={Styles.manaContent}>
							<div className={Styles.manaImg}>
								<img src={manaImg} alt={"mana image"}/>
							</div>
							<div className={Styles.description}>
								<p>{L.manaEnchanting.the_mana_description}</p>
							</div>
						</div>
					</div>
					<div className={Styles.pool}>
						<div className={Styles.poolContent}>
							<div className={Styles.poolImg}>
								<img src={poolImg} alt={"pool image"}/>
							</div>
							<p>{L.manaEnchanting.the_avalible_amount}</p>
						</div>
					</div>
					<DecoratedTitleView text={L.manaEnchanting.title.how_to_enchant}/>
					<div className={Styles.howToEnchant}>
						<div className={Styles.milkiway}/>
						<div className={Styles.manaInfo}>
							<h3>{L.manaEnchanting.stake}</h3>
							<h5>{L.manaEnchanting.your_ancient}</h5>
							<p>{L.manaEnchanting.the_amount_mana}</p>
						</div>
						<div className={Styles.manaInfo}>
							<h3>{L.manaEnchanting.collect}</h3>
							<h5>{L.manaEnchanting.your_mana_regularly}</h5>
							<p>{L.manaEnchanting.your_cauldron_holds}</p>
						</div>
						<div className={Styles.manaInfo}>
							<h3>{L.manaEnchanting.spend}</h3>
							<h5>{L.manaEnchanting.your_mana_on_pack}</h5>
							<p>{L.manaEnchanting.the_hero}</p>
						</div>
					</div>
					<div className={Styles.callHero} id="pack">
						<DecoratedTitleView text={L.manaEnchanting.title.call_for_the_hero}/>
						<div className={Styles.heroContent}>
							<div className={Styles.heroImg}>
								<img src={heroPackImg} alt={"hero pack"}/>
							</div>
							<div className={Styles.info}>
								<p>{L.manaEnchanting.with_this}</p>
								<div className={Styles.rarity}>
									{booksRarity.map(item => (
										<RarityRow
											key={`books-rarity-${item.rarity}`}
											rarity={item.rarity}
											value={item.value}
										/>
									))}
								</div>
								<div className={Styles.buyForm}>
									{/*<div className={Styles.manaPrice}>*/}
									{/*	<img src={manaImg} alt={"mana icon"}/>*/}
									{/*	<div>12 344</div>*/}
									{/*</div>*/}
									{/*<div className={Styles.buttonBuy}>*/}
									{/*	<Button text={"Buy"} style={ButtonStyle.ACTION}/>*/}
									{/*</div>*/}
								</div>
							</div>
						</div>
					</div>
					<div className={Styles.cards} id="heroes">
						<div className={Styles.cardsContent}>
							<DecoratedTitleView text={L.manaEnchanting.title.cards}/>
							<Cards/>
						</div>
					</div>
					<div className={Styles.footerAndSocial}>
						<div className={metaFrame.main_container}>
							<div className={social.follow_container}>
								<h1>{L.manaEnchanting.follow_tribal_books}</h1>
								<p className={metaFrame.text_style}>
									{L.manaEnchanting.hear_even}</p>
								<div className={social.follow_social_box}>
									<a href="https://twitter.com/tribalbooks" target="_blank">
										<div className={social.twitter_box}>
											<img src={middleTwitter} alt="twiter"/>
										</div>
										Twitter
									</a>
									<a href="https://t.me/tribalbooks" target="_blank">
										<img src={middleTelegram} alt="telegram"/>
										Telegram
									</a>
									<a href="https://tribalbooks.medium.com/" target="_blank">
										<img src={middleMedium} alt="medium"/>
										Medium
									</a>
									<a href="https://www.youtube.com/channel/UCIxBDLtqaA984DcnrrPrfMw" target="_blank">
										<img src={middleYoutube} alt="medium"/>
										Youtube
									</a>
									<a href="https://www.facebook.com/tribalbooksgame/" target="_blank">
										<img src={middleFacebook} alt="medium"/>
										Facebook
									</a>
								</div>
							</div>
						</div>
						<Footer/>
					</div>
				</div>
			</div>
		</LayoutBase>
	);
});

export default manaEnchantingPage;
